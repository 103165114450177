<template>
    <div class="outerBox">
        <div class="innerBox">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name:'CFrame'
}
</script>

<style scoped>
.outerBox{
    margin:20px;
    border-radius: 6px;
    background: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
                /* linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)); */

                /* border: 1px solid red; */

    /* border-image-source: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%); */
}

.innerBox{
    margin:1px;
    border-radius: 6px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
}
</style>